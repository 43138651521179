<template>
  <div class="my-project-requests-request-ownership">
    <data-loading :loading="dataLoading" :lock="true"/>
    <form @input="validateForm" @change="validateForm" @submit.stop.prevent="submitForm">
      <div class="h6">
        Руководитель / Индивидуальный предприниматель
        <c-tooltip text="Если Вы являететсь ИП, то в поле «Являетесь учредителем» поставить «Да» и в поле «Доля в компании» поставить 100%"/>
      </div>
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="form-group">
            <label class="text-muted" for="head-full-name">ФИО руководителя</label>
            <input v-model="ownership.head.fullName"
                   @blur="createFirstFounderFromHead"
                   class="form-control form-control-lg"
                   id="head-full-name"
                   type="text"
                   placeholder="ФИО руководителя"
                   required>
            <input-errors :errors="errors['head.fullName']"/>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="head-phone">Телефон</label>
            <input-mask v-model="ownership.head.phone"
                        mask="+7 (999) 999-9999"
                        @blur="createFirstFounderFromHead"
                        class="form-control form-control-lg"
                        id="head-phone"
                        type="text"
                        placeholder="+7 (000) 000-0000"
                        required/>
            <input-errors :errors="errors['head.phone']"/>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="form-group">
            <label class="text-muted" for="head-email">Эл. почта</label>
            <input v-model="ownership.head.email"
                   @blur="createFirstFounderFromHead"
                   class="form-control form-control-lg"
                   id="head-email"
                   type="email"
                   placeholder="email@email.ru"
                   required>
            <input-errors :errors="errors['head.email']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="text-muted" for="head-passport-registration-address">Адрес регистрации</label>
            <input v-model="ownership.head.passport.registrationAddress"
                   @blur="createFirstFounderFromHead"
                   class="form-control form-control-lg"
                   id="head-passport-registration-address"
                   type="text"
                   placeholder="Адрес регистрации"
                   required>
            <input-errors :errors="errors['head.passport.registrationAddress']"/>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="head-passport-number">Серия и номер паспорта</label>
            <input-mask v-model="ownership.head.passport.number"
                        mask="9999 999999"
                        @blur="createFirstFounderFromHead"
                        class="form-control form-control-lg"
                        id="head-passport-number"
                        type="text"
                        placeholder="0000 000000"
                        required/>
            <input-errors :errors="errors['head.passport.number']"/>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="head-passport.issue-date">Дата выдачи паспорта</label>
            <date-picker v-model="ownership.head.passport.issueDate" locale="ru" :update-on-input="false">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="bi-calendar"></i>
                    </div>
                  </div>
                  <input :value="inputValue"
                         v-on="inputEvents"
                         @change="createFirstFounderFromHead"
                         class="form-control form-control-lg"
                         id="head-passport.issue-date"
                         placeholder="17.11.2019"
                         autocomplete="off"
                         required>
                </div>
              </template>
            </date-picker>
            <input-errors :errors="errors['head.passport.issueDate']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="head-passport-department-code">Код подразделения</label>
            <input-mask v-model="ownership.head.passport.departmentCode"
                        mask="999-999"
                        @blur="createFirstFounderFromHead"
                        class="form-control form-control-lg"
                        id="head-passport-department-code"
                        type="text"
                        placeholder="000-000"
                        required/>
            <input-errors :errors="errors['head.passport.departmentCode']"/>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-9">
          <div class="form-group">
            <label class="text-muted" for="head-passport-department">Орган выдачи</label>
            <input v-model="ownership.head.passport.department"
                   @blur="createFirstFounderFromHead"
                   class="form-control form-control-lg"
                   id="head-passport-department"
                   type="text"
                   placeholder="Орган выдачи"
                   required>
            <input-errors :errors="errors['head.passport.department']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted" for="head-passport.birth-date">Дата рождения</label>
            <date-picker v-model="ownership.head.passport.birthDate" locale="ru" :update-on-input="false">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="bi-calendar"></i>
                    </div>
                  </div>
                  <input :value="inputValue"
                         v-on="inputEvents"
                         @change="createFirstFounderFromHead"
                         class="form-control form-control-lg"
                         id="head-passport.birth-date"
                         placeholder="17.11.2003"
                         autocomplete="off"
                         required>
                </div>
              </template>
            </date-picker>
            <input-errors :errors="errors['head.passport.birthDate']"/>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-9">
          <div class="form-group">
            <label class="text-muted" for="head-passport-birth-place">Место рождения</label>
            <input v-model="ownership.head.passport.birthPlace"
                   @blur="createFirstFounderFromHead"
                   class="form-control form-control-lg"
                   id="head-passport-birth-place"
                   type="text"
                   placeholder="Место рождения"
                   required>
            <input-errors :errors="errors['head.passport.birthPlace']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
          <div class="form-group">
            <label class="text-muted">Является учредителем</label>
            <div class="input-group">
              <div class="custom-control custom-radio">
                <input v-model="ownership.head.founder"
                       :value="true"
                       @change="createFirstFounderFromHead"
                       type="radio"
                       class="custom-control-input"
                       id="head-founder-yes">
                <label class="custom-control-label" for="head-founder-yes">Да</label>
              </div>
              <div class="custom-control custom-radio ml-4">
                <input v-model="ownership.head.founder"
                       :value="false"
                       @change="createFirstFounderFromHead"
                       type="radio"
                       class="custom-control-input"
                       id="head-founder-no">
                <label class="custom-control-label" for="head-founder-no">Нет</label>
              </div>
            </div>
            <input-errors :errors="errors['head.founder']"/>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label class="text-muted" for="head-shares">Доля в компании</label>
            <div class="input-group">
              <input v-model="ownership.head.shares"
                     :disabled="!ownership.head.founder"
                     @blur="createFirstFounderFromHead"
                     class="form-control form-control-lg"
                     id="head-shares"
                     type="number"
                     min="0"
                     max="100"
                     step="1"
                     placeholder="20"
                     required>
              <div class="input-group-append">
                <div class="input-group-text">%</div>
              </div>
            </div>
            <input-errors :errors="errors['head.shares']"/>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-6">
          <div class="form-group">
            <label class="text-muted">Согласен выступить поручителем</label>
            <div class="input-group">
              <div class="custom-control custom-radio">
                <input v-model="ownership.head.guarantor"
                       :value="true"
                       @change="createFirstFounderFromHead"
                       type="radio"
                       class="custom-control-input"
                       id="head-guarantor-yes">
                <label class="custom-control-label" for="head-guarantor-yes">Да</label>
              </div>
              <div class="custom-control custom-radio ml-4">
                <input v-model="ownership.head.guarantor"
                       :value="false"
                       @change="createFirstFounderFromHead"
                       type="radio"
                       class="custom-control-input"
                       id="head-guarantor-no">
                <label class="custom-control-label" for="head-guarantor-no">Нет</label>
              </div>
              <div class="custom-control custom-radio ml-4">
                <input v-model="ownership.head.guarantor"
                       :value="undefined"
                       @change="createFirstFounderFromHead"
                       type="radio"
                       class="custom-control-input"
                       id="head-guarantor-undefined">
                <label class="custom-control-label" for="head-guarantor-undefined">пока неизвестно</label>
              </div>
            </div>
            <input-errors :errors="errors['head.guarantor']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-9 col-lg-7 col-xl-6">
          <div class="form-group">
            <file-upload :files="ownership.head.passport.scanCopies">
              Прикрепить фото паспорта. Первую страницу и страницу с пропиской
            </file-upload>
            <input-errors :errors="errors['head.passport.scanCopies']"/>
          </div>
        </div>
      </div>
      <div class="h6">Учредители</div>
      <div v-for="(founder, index) in ownership.founders">
        <div class="text-right">
          <a @click.stop.prevent="removeFounder(index)" class="text-danger" href="#">
            <i class="bi-x"></i>
          </a>
        </div>
        <div class="row">
          <div class="col-12 col-lg-5">
            <div class="form-group">
              <label class="text-muted" :for="'founders-' + index + '-full-name'">ФИО</label>
              <input v-model="founder.fullName"
                     class="form-control form-control-lg"
                     :id="'founders-' + index + '-full-name'"
                     type="text"
                     placeholder="ФИО"
                     required>
              <input-errors :errors="errors['founders.' + index + '.fullName']"/>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="form-group">
              <label class="text-muted" :for="'founders-' + index + '-phone'">Телефон</label>
              <input-mask v-model="founder.phone"
                          mask="+7 (999) 999-9999"
                          class="form-control form-control-lg"
                          :id="'founders-' + index + '-phone'"
                          type="text"
                          placeholder="+7 (000) 000-0000"
                          required/>
              <input-errors :errors="errors['founders.' + index + '.phone']"/>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-4">
            <div class="form-group">
              <label class="text-muted" :for="'founders-' + index + '-email'">Эл. почта</label>
              <input v-model="founder.email"
                     class="form-control form-control-lg"
                     :id="'founders-' + index + '-email'"
                     type="email"
                     placeholder="email@email.ru"
                     required>
              <input-errors :errors="errors['founders.' + index + '.email']"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <div class="form-group">
              <label class="text-muted" :for="'founders-' + index + '-shares'">Доля в компании</label>
              <div class="input-group">
                <input v-model="founder.shares"
                       class="form-control form-control-lg"
                       :id="'founders-' + index + '-shares'"
                       type="number"
                       min="0"
                       max="100"
                       step="1"
                       placeholder="20"
                       required>
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
              <input-errors :errors="errors['founders.' + index + '.shares']"/>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-9">
            <div class="form-group">
              <label class="text-muted">Согласен выступить поручителем</label>
              <div class="input-group">
                <div class="custom-control custom-radio">
                  <input v-model="founder.guarantor"
                         :value="true"
                         type="radio"
                         class="custom-control-input"
                         :id="'founders-' + index + '-guarantor-yes'">
                  <label class="custom-control-label" :for="'founders-' + index + '-guarantor-yes'">Да</label>
                </div>
                <div class="custom-control custom-radio ml-4">
                  <input v-model="founder.guarantor"
                         :value="false"
                         type="radio"
                         class="custom-control-input"
                         :id="'founders-' + index + '-guarantor-no'">
                  <label class="custom-control-label" :for="'founders-' + index + '-guarantor-no'">Нет</label>
                </div>
                <div class="custom-control custom-radio ml-4">
                  <input v-model="founder.guarantor"
                         :value="undefined"
                         type="radio"
                         class="custom-control-input"
                         :id="'founders-' + index + '-guarantor-undefined'">
                  <label class="custom-control-label" :for="'founders-' + index + '-guarantor-undefined'">пока неизвестно</label>
                </div>
              </div>
              <input-errors :errors="errors['founders.' + index + '.guarantor']"/>
            </div>
          </div>
        </div>
        <div v-if="index > 0 || !isHeadFounder" class="row">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6">
            <div class="form-group">
              <file-upload :files="founder.passportScanCopies">
                Прикрепить фото паспорта. Первую страницу и страницу с пропиской
              </file-upload>
              <input-errors :errors="errors['founders.' + index + '.passportScanCopies']"/>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-4">
        <button @click="addFounder" type="button" class="btn btn-outline-success">Добавить учредителя</button>
      </div>
      <input-errors :errors="errors['someFounder']"/>
      <hr>
      <div class="row">
        <div class="col-12 col-md-7 col-lg-8 col-xl-9">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input v-model="ownership.agreement" type="checkbox" class="custom-control-input" id="agreement" required>
              <label class="custom-control-label" for="agreement"></label>
              <public-link to="/page/soglasie-na-obrabotku-i-peredachu-personalnykh-dannykh"
                           target="_blank"
              >Подтверждаю получение мною согласия третьих лиц на передачу их персональных данных на обработку
              </public-link>
            </div>
            <input-errors :errors="errors['agreement']"/>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 col-xl-3">
          <div class="form-group text-right">
            <router-link :to="{ name: 'myProjectRequests.request.general', params: { uuid } }"
                         class="btn btn-lg btn-outline-info mr-3">
              Назад
            </router-link>
            <button type="submit" class="btn btn-lg btn-success">Продолжить</button>
          </div>
        </div>
      </div>
    </form>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { CTooltip, DataLoading, FileUpload, InputErrors, Processing, PublicLink } from "@/components/common";
import InputMask from "primevue/inputmask";
import { DatePicker } from "v-calendar";
import { OwnershipManager } from "../managers/OwnershipManager";

const ownershipManager = new OwnershipManager()

export default {
  name: "Ownership",
  components: {
    DataLoading,
    DatePicker,
    CTooltip,
    FileUpload,
    InputMask,
    InputErrors,
    Processing,
    PublicLink
  },
  async mounted() {
    this.dataLoading = true;
    try {
      Object.assign(this.ownership, await ownershipManager.getOne(this.uuid));
      if (this.ownership.founders.length === 0) {
        this.addFounder();
      }
      this.validateForm();
    } finally {
      this.dataLoading = false;
    }
  },
  data() {
    return {
      ownership: {
        head: {
          passport: {
            scanCopies: []
          }
        },
        founders: [
          {
            passportScanCopies: []
          }
        ],
      },
      errors: {},
      dataLoading: false,
      processing: false
    };
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    isHeadFounder() {
      return this.ownership.head.founder && (!this.ownership.founders[0].phone || this.ownership.founders[0].phone === this.ownership.head.phone);
    }
  },
  methods: {
    validateForm() {
      let numberRequiredFields = 16; // 12 + button + agreement + someFounder
      if (this.ownership.head.founder) {
        numberRequiredFields += 5 // shares + 4 founder fields (without files)
        numberRequiredFields += (this.ownership.founders.length - 1) * 5;
      } else {
        numberRequiredFields += this.ownership.founders.length * 5;
      }
      const errors = {};
      if (!this.ownership.head.fullName) {
        errors['head.fullName'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.phone) {
        errors['head.phone'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.email) {
        errors['head.email'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.registrationAddress) {
        errors['head.passport.registrationAddress'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.number) {
        errors['head.passport.number'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.issueDate) {
        errors['head.passport.issueDate'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.departmentCode) {
        errors['head.passport.departmentCode'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.department) {
        errors['head.passport.department'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.birthDate) {
        errors['head.passport.birthDate'] = ["Поле обязательное для заполнения"];
      }
      if (!this.ownership.head.passport.birthPlace) {
        errors['head.passport.birthPlace'] = ["Поле обязательное для заполнения"];
      }
      if (this.ownership.head.founder === undefined) {
        errors['head.founder'] = ["Укажите статус учредителя"];
      }
      if (this.ownership.head.founder) {
        if (this.ownership.head.shares === undefined) {
          errors['head.shares'] = ["Поле обязательное для заполнения"];
        } else if (this.ownership.head.shares <= 0) {
          errors['head.shares'] = ["Значение должно быть больше ноля"];
        } else if (this.ownership.head.shares > 100) {
          errors['head.shares'] = ["Значение не может быть больше 100"];
        }
      }
      if (this.ownership.head.passport.scanCopies.length === 0) {
        errors['head.passport.scanCopies'] = ["Необходимо прикрепить фото паспорта"];
      }
      if (this.ownership.founders.length === 0) {
        errors["someFounder"] = ['Должен быть указан минимум один учредитель'];
      } else {
        this.ownership.founders.forEach((founder, index) => {
          if (!founder.fullName) {
            errors['founders.' + index + '.fullName'] = ["Поле обязательное для заполнения"];
          }
          if (!founder.phone) {
            errors['founders.' + index + '.phone'] = ["Поле обязательное для заполнения"];
          }
          if (!founder.email) {
            errors['founders.' + index + '.email'] = ["Поле обязательное для заполнения"];
          }
          if (founder.shares === undefined) {
            errors['founders.' + index + '.shares'] = ["Поле обязательное для заполнения"];
          } else if (founder.shares <= 0) {
            errors['founders.' + index + '.shares'] = ["Значение должно быть больше ноля"];
          } else if (founder.shares > 100) {
            errors['founders.' + index + '.shares'] = ["Значение не может быть больше 100"];
          }
          if (this.ownership.head.passport.scanCopies.length === 0) {
            errors['founders.' + index + '.passportScanCopies'] = ["Необходимо прикрепить фото паспорта"];
          }
        });
      }
      if (!this.ownership.agreement) {
        errors["agreement"] = ['Пожалуйста, отметьте это поле'];
      }
      this.errors = errors;
      const numberErrors = Object.keys(errors).length;
      this.$emit("progress", (numberRequiredFields - numberErrors - 1) / numberRequiredFields);
      return numberErrors === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          await ownershipManager.save(this.uuid, this.ownership);
          await this.$router.push({ name: "myProjectRequests.request.collaterals", params: { uuid: this.uuid } });
        } finally {
          this.processing = false;
        }
      }
    },
    addFounder() {
      this.ownership.founders.push({ passportScanCopies: [] });
      this.validateForm();
    },
    removeFounder(index) {
      if (this.ownership.founders.length <= 1) {
        this.$notifications.addAlert("Должен быть указан как минимум один учредитель!");
      } else {
        this.$confirm("Вы хотите удалить учредителя?", () => this.ownership.founders.splice(index, 1));
      }
      this.validateForm();
    },
    createFirstFounderFromHead() {
      if (this.isHeadFounder) {
        const { fullName, phone, email, shares, guarantor } = this.ownership.head;
        this.ownership.founders[0] = { fullName, phone, email, shares, guarantor };
      }
      this.validateForm();
    }
  }
}
</script>
