import { createUploadedFileFromFile } from "@/utils";
import { Phone } from "@/utils/api-client/models/common/Phone";
import { UploadedFile } from "@/utils/api-client/models/upload/UploadedFile";
import { createProjectRequestManager } from "@/utils/di";

type Ownership = {
  readonly head: {
    readonly fullName: string;
    readonly phone: string;
    readonly email: string;
    readonly passport: {
      readonly number: string;
      readonly issueDate?: Date;
      readonly departmentCode: string;
      readonly department: string;
      readonly birthDate?: Date;
      readonly birthPlace: string;
      readonly registrationAddress: string;
      readonly scanCopies: Array<UploadedFile>;
    };
    readonly founder: boolean;
    readonly shares?: number;
    readonly guarantor?: boolean;
  };
  readonly founders: Array<{
    readonly fullName: string;
    readonly phone: string;
    readonly email: string;
    readonly passportScanCopies: Array<UploadedFile>;
    readonly founder: boolean;
    readonly shares: number;
    readonly guarantor?: boolean;
  }>;
}

export class OwnershipManager {
  async getOne(uuid: string): Promise<Ownership> {
    const manager = await createProjectRequestManager();
    const data = await manager.getOwnership(uuid);
    const { phone, passport, ...headData } = data.head || {};
    const { issueDate, birthDate, scanCopies, ...passportData } = passport || {};
    return {
      head: {
        ...headData,
        phone: phone && "+" + phone.countryCode + phone.number,
        passport: {
          ...passportData,
          issueDate: issueDate ? new Date(issueDate) : undefined,
          birthDate: birthDate ? new Date(birthDate) : undefined,
          scanCopies: (scanCopies || []).map((file) => createUploadedFileFromFile(file))
        }
      },
      founders: (data.founders || []).map(({ phone, passportScanCopies, ...founderData }) => ({
        ...founderData,
        phone: phone && "+" + phone.countryCode + phone.number,
        passportScanCopies: (passportScanCopies || []).map((file) => createUploadedFileFromFile(file))
      }))
    };
  }

  async save(uuid: string, ownership: Ownership): Promise<void> {
    const { phone, passport, ...headData } = ownership.head;
    const { issueDate, birthDate, scanCopies, ...passportData } = passport;
    const phoneCountryCode = "7";
    const phoneNumber = phone.replace(/\D/g, "").substr(1);

    if (!issueDate || !birthDate) {
      throw new Error("Invalid data");
    }

    const manager = await createProjectRequestManager();
    return manager.setOwnership(uuid, {
      head: {
        ...headData,
        phone: new Phone(phoneCountryCode, phoneNumber),
        passport: {
          ...passportData,
          issueDate: issueDate.toISOString(),
          birthDate: birthDate.toISOString(),
          scanCopies: (scanCopies || []).map(({ url, name }) => ({ url, name }))
        }
      },
      founders: ownership.founders.map(({ phone, passportScanCopies, ...founderData }) => {
        const phoneCountryCode = "7";
        const phoneNumber = phone.replace(/\D/g, "").substr(1);

        return {
          ...founderData,
          phone: new Phone(phoneCountryCode, phoneNumber),
          passportScanCopies: (passportScanCopies || []).map(({ url, name }) => ({ url, name }))
        };
      })
    });
  }
}
